@tailwind base;
@tailwind components;
@tailwind utilities;


h1{
  font-size: 32px;
}

h2{
  font-size: 28px;
}

h3{
  font-size: 24px;
}

h4{
  font-size: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Apply background color */
    box-shadow: 0 0 0 1000px white inset !important; /* Standard shadow for non-webkit browsers */
    -webkit-text-fill-color: #000 !important; /* Text color */
}

.ag-theme-alpine-dark .ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine-dark .ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* Ensures centered alignment */
  white-space: normal;
  text-overflow: ellipsis;
}

.body-mode-dark {
  margin: 0;
  font-family: Arial,'Lucida Sans','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  line-height: 1.1;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}


.bg-mode-dark {
  background-color: black;
}

.forcolor-mode-dark {
  color: rgb(200,200,200);
}

.bg-color-mode-dark {
  color:black;
}

.border-mode-dark {
  border: 1px solid rgba(75,75,75,1);
}

.panel-mode-dark {
  background-color: rgb(35,35,35);
  box-shadow: 5px 5px 5px rgba(15,15,15,1);
}

.title-mode-dark {
  color: rgb(235,235,235);
  font-size:32px;
}

.subtitle-mode-dark {
  color: rgb(150,150,150);
  font-size:24px;
}

.primary-color-mode-dark {
  color: rgb(235,235,235);
}

.secondary-color-mode-dark {
  color: rgb(150,150,150);
}

.tertiary-color-mode-dark {
  color: rgb(50,50,50);
}


.button-mode-dark {
  display: flex;
  color: rgb(255,255,255);
  cursor: pointer; 
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background-color: rgb(150,150,150);
  border: 1px solid rgb(150,150,150);
  min-width: 100px;
  min-height: 35px;
  border-radius: 5px;
  transition: 0.5s ease;
}

.button-mode-dark:hover {
  background-color: rgb(200,200,200);
  color: gray;
  transform: scale(1.1);
}

.button-mode-secondary-dark {
  display: flex;
  color: rgb(200,200,200);
  cursor: pointer; 
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background-color: rgb(75,75,75);
  border: 1px solid rgb(75,75,75);
  min-width: 100px;
  min-height: 35px;
  border-radius: 5px;
  transition: 0.5s ease;
}

.button-mode-secondary-dark:hover {
  background-color: rgb(200,200,200);
  color: rgb(100,100,100);
  transform: scale(1.1);
}



.input-mode-dark {
    display: flex;
    cursor: pointer; 
    font-size: 14px;
    padding: 10px;
    align-items: center;
    border: 2px solid rgb(50,50,50);
    background-color: rgba(0,0,0,0);
    width: 100%;
    border-radius: 5px;
    transition: All 500ms ease-in-out;
    outline: none;
  }

  .select-mode-dark {
    display: flex;
    cursor: pointer; 
    font-size: 14px;
    padding: 10px;
    align-items: center;
    border: 2px solid rgb(50,50,50);
    background-color: rgba(0,0,0,0);
    width: 100%;
    border-radius: 5px;
    transition: All 500ms ease-in-out;
    outline: none;
  }

  .pill-mode-dark {
    cursor: pointer; 
    font-size: 14px;
    padding: 10px;
    align-items: center;
    color: rgb(150,150,150);
    background-color: rgb(75,75,75);
    border-radius: 20px;
    transition: All 500ms ease-in-out;
    margin: 5px;
  }

  .pill-mode-dark:hover {
    transform: scale(1.1);
    color: black;
    background-color: rgb(200,200,200);
    font-style: bold;
    
  }

  .pill-mode-dark-clicked {
    background-color: rgb(200,200,200);
    font-style: bold;
    cursor: pointer; 
    font-size: 14px;
    padding: 10px;
    align-items: center;
    color: black;
    border-radius: 20px;
    transition: All 500ms ease-in-out;
    margin: 5px;
  }
  


.card-mode-dark {
  display: flex;
  direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(50, 50, 50);
  transition: 0.5s ease;
  box-shadow: 5px 5px 10px rgba(200,200,200,0.5);
  border-radius: 5px;
  min-width: 200px;
  min-height: 100px;
  cursor: pointer;
  padding: 10px;
}

.card-mode-dark:hover {
  background-color: rgb(200,200,200);
  color: rgb(100,100,100);
  transform: scale(1.05);
}

.header-strip-mode-dark {
  background-color: rgb(50,50,50);
  color: rgb(200,200,200)
}



:root {
  --grid-line-mode-dark: rgb(150,150,150);
  --primary-color-mode-dark: rgb(255,255,255);
  --tick-color-mode-dark:rgb(200,200,200);
}


.grid-line-mode-dark {
  border-color: var(--grid-line-mode-dark);
}


.input-maincontainer-mode-dark{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: rgb(235,235,235);
  border-radius: 5px;
  border: 1px solid rgb(50,50,50);
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
  align-items: center;
}

.input-input-mode-dark{
  position: absolute;
  display: flex;
  color: rgb(0, 100, 255);
  background-color: rgba(0,0,0,0);
  padding: 5px;
  border-radius: 5px;
  width: 99%;
  outline: none;
  transition: all 500ms ease;
  height: 100%;
  font-size: 14px;
  margin-top: 5px;
}


.input-label-mode-dark{
  position: absolute;
  display: flex;
  align-items: center;
  color: rgb(150, 150, 150);
  background-color: rgba(0,0,0,0);
  padding: 5px;
  border-radius: 5px;
  width: 99%;
  outline: none;
  transition: all 500ms ease;
  height: 100%;
  font-size: 14px;
}

.input-dropdown-mode-dark{
  position: absolute;
  background: rgba(0,0,0,0.75);
  width: 99%;
  transition: all 500ms ease;
  overflow-y: auto;
  padding: 5px;
  z-index: 9999;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 5px 10px rgba(100,100,100,0.5);
  border: 3px solid rgb(50,50,50);
  font-size: 14px;
}

.input-option-mode-dark{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.input-option-mode-dark:hover{
  background-color: rgb(200,200,200);
  color: black;
  font-size: 14px;
}


.shadow-mode-dark{
  box-shadow: 5px 5px 15px rgba(25,25,25,0.5)
}


.datepicker-mode-dark{
  display: flex;
  background-color: black;
  color: rgb(0, 100, 255);
  border-radius: 5px;
  border: 1px solid rgb(50,50,50);
  width: 100%;
  height: 50px;
  font-size: 14px;
  margin-bottom: 5px;
  align-items: center;
  font-size: 14px;
  padding: 5px;
  outline: none;
  transition: all 500ms ease;
}


.navbar-item-mode-dark {
  color: rgb(200,200,200);
  padding: 10px;
  margin: 2px;
}


.navbar-item-mode-dark:hover {
  color: rgb(100,100,100);
  background-color: rgb(255,255,255);
  transition: all 500ms ease;
  border-radius: 5px;
}

.navbar-item-selected-mode-dark {
  color: rgb(100,100,100);
  background-color: rgb(200,200,200);
  transition: all 500ms ease;
  border-radius: 5px;
  padding: 10px;
  margin: 2px;
}

.icon-color-mode-dark{
  color: rgb(100,100,100);
}

.icon-color-mode-dark:hover{
  color: rgb(200,200,200);
}

.icon-color-mode-dark-clicked{
  color: rgb(200,200,200);
}


.chat-topic-mode-dark {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  color: rgb(200,200,200);
  border-radius: 5px;
  margin-bottom: 5px;
  transition: All 300ms ease-in-out;
  cursor: pointer;
}

.chat-topic-mode-dark:hover{
  transform: scale(1.05);
  background-color: rgb(75,75,75);
}

.chat-topic-clicked-mode-dark {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  margin-bottom: 5px;
  transition: All 300ms ease-in-out;
  cursor: pointer;
  background-color: rgb(75,75,75);
}

.chat-prompt-mode-dark{
  display: flex;
  width: 60%;
  align-self: start;
  height: fit-content;
  padding: 10px;
  background-color: rgba(50,150,255,1);
  color: white;
  box-shadow: 5px 5px 5px rgba(50,50,50,0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.chat-response-mode-dark{
  display: flex;
  flex-direction: column;
  align-self: end;
  width: 75%;
  height: fit-content;
  padding: 10px;
  background-color: rgb(50,50,50);
  color: white;
  box-shadow: 5px 5px 5px rgba(25,25,25,0.5);
  border-radius: 10px;
  margin-bottom: 50px;
  line-height: 1.5;
}
.kanban-board-mode-light {
    color: "black";
    display: flex;
    gap: 16px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    height: "100%"
  }
  
  .kanban-column-mode-light {
    color: rgb(50,50,50);
    background-color: #f4f4f4;
    border-radius: 8px;
    width: 300px;
    padding: 10px;
    overflow-y: auto;
  }
  
  .kanban-column-mode-light h3 {
    text-align: center;
    font-size: 16px;
  }
  
  .kanban-column-cards-mode-light {
    padding: 8px;
    min-height: 100px;

  }
  
  .kanban-card-mode-light {
    color: "black";
    background-color: #fff;
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: grab;
    height: 150px;
    overflow-y: hidden;
    font-size: 12px;
  }
  
  .kanban-card:active {
    cursor: grabbing;
  }
  

  .kanban-board-mode-dark {
    color: "rgb(235,235,235)";
    display: flex;
    gap: 16px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    height: "100%"
  }
  
  .kanban-column-mode-dark {
    background-color: rgb(50,50,50);
    border-radius: 8px;
    width: 300px;
    padding: 10px;
    overflow-y: "auto";
  }
  
  .kanban-column-mode-dark h3 {
    text-align: center;
    font-size: 16px;
    overflow-y: "auto";
  }
  
  .kanban-column-cards-mode-dark {
    padding: 8px;
    min-height: 100px;
    overflow-y: "auto";
  }
  
  .kanban-card-mode-dark {
    color: "black";
    background-color: rgb(150,150,150);
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid rgb(200,200,200);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: grab;
    height: 150px;
    overflow-y: "hidden";
  }
  
  .kanban-card:active {
    cursor: grabbing;
  }
.workflow-node-mode-light{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100px;
    border: 1px solid lightgray;
    background-color: rgb(255,255,255);
    color: gray;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    cursor: move;
    z-index: 10;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
}


.workflow-node-mode-dark{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100px;
    border: 1px solid rgb(75,75,75);
    background-color: rgb(50,50,50);
    color: rgb(200,200,200);
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    cursor: move;
    z-index: 10;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
    padding: 5px;
    box-shadow: 5px 5px 5px rgba(50,50,50,0.5);
}






                
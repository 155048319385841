.app {
    text-align: center;
  }
  
  .racks-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .rack {
    border: 2px solid #000;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
    min-height: 400px;
    background-color: #f9f9f9;
  }
  
  .servers {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
  
  .server {
    padding: 5px;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: #d9d9d9;
    cursor: grab;
  }
  
  .server:active {
    cursor: grabbing;
  }
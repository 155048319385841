@tailwind base;
@tailwind components;
@tailwind utilities;


h1{
  font-size: 32px;
}

h2{
  font-size: 28px;
}

h3{
  font-size: 24px;
}

h4{
  font-size: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Apply background color */
    box-shadow: 0 0 0 1000px white inset !important; /* Standard shadow for non-webkit browsers */
    -webkit-text-fill-color: #000 !important; /* Text color */
}

.body-mode-light {
  margin: 0;
  font-family: Arial,'Lucida Sans','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: black;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

/* Hides the border around the entire grid */
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}


.bg-mode-light {
  background-color: rgb(255,255,255);
}

.forcolor-mode-light {
  color: rgb(64, 6, 6);
}

.bg-color-mode-light {
  color: rgb(255,255,255);
}

.border-mode-light {
  border: 1px solid rgba(225,225,225,1)
}

.panel-mode-light{
  background-color: rgb(245,245,245);
  box-shadow: 5px 5px 10px rgba(200,200,200,1);
}

.title-mode-light {
color: black;
font-size:32px;
}

.subtitle-mode-light {
color: rgb(200,200,200);
font-size:24px;
}

.primary-color-mode-light {
  color: black;
}

.secondary-color-mode-light {
  color: rgb(150,150,150);
  }

  .tertiary-color-mode-light {
    color: rgb(235,235,235);
  }
  

.button-mode-light {
  display: flex;
  color: rgb(255,255,255);
  cursor: pointer; 
  font-size: 14px;
  padding: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: rgb(0,100,225);
  border: 1px solid lightgray;
  min-width: 100px;
  min-height: 35px;
  border-radius: 5px;
  transition: 0.5s ease;
}


.button-mode-light:hover {
  background-color: rgb(0,150,255);
  color: white;
  transform: scale(1.1);
}

.button-mode-secondary-light {
  display: flex;
  color: rgb(100,100,100);
  cursor: pointer; 
  font-size: 14px;
  padding: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: rgb(200,200,200);
  border: 1px solid lightgray;
  min-width: 100px;
  min-height: 35px;
  border-radius: 5px;
  transition: 0.5s ease;
}

.button-mode-secondary-light:hover {
  background-color: rgb(100,100,100);
  color: white;
  transform: scale(1.1);
}

.input-mode-light {
  display: flex;
  cursor: pointer; 
  font-size: 14px;
  padding: 10px;
  align-items: center;
  border: 2px solid rgb(235,235,235);
  background-color: rgba(0,0,0,0);
  width: 100%;
  border-radius: 5px;
  transition: All 500ms ease-in-out;
  outline: none;
}

.select-mode-light {
  display: flex;
  cursor: pointer; 
  font-size: 14px;
  padding: 10px;
  align-items: center;
  background-color: rgba(0,0,0,0);
  width: 100%;
  border-radius: 5px;
  transition: All 500ms ease-in-out;
  outline: none;
}


.pill-mode-light {
  display: flex;
  font-size: 14px;
  cursor: pointer; 
  padding: 10px;
  align-items: center;
  border: 2px solid rgb(200,200,200);
  background-color: rgb(200,200,200);
  border-radius: 20px;
  transition: All 500ms ease-in-out;
  margin: 5px;
}

.pill-mode-light:hover{
  transform: scale(1.1);
  color: white;
  background-color: rgb(0,100,225);
  font-style: bold;
}

.pill-mode-light-clicked {
  display: flex;
  font-size: 14px;
  cursor: pointer; 
  padding: 10px;
  align-items: center;
  border: 2px solid rgb(235,235,235);
  border-radius: 20px;
  transition: All 500ms ease-in-out;
  color: white;
  background-color: rgb(0,100,225);
  font-style: bold;
  margin: 5px;
}


.card-mode-light {
  display: flex;
  direction: column;
  justify-content: center;
  background-color: rgb(235, 235, 235);
  transition: 0.5s ease;
  box-shadow: 5px 5px 15px rgb(235,235,235);
  border-radius: 5px;
  min-width: 200px;
  min-height: 100px;
  cursor: pointer;
  padding: 10px;
}

.card-mode-light:hover {
  background-color: rgb(200,200,200);
  transform: scale(1.1);
}

.header-strip-mode-light {
  background-color: rgb(235,235,235);
  color: rgb(100,100,100);
  
}


:root {
  --grid-line-mode-light: rgb(150,150,150);
  --primary-color-mode-light: black;
  --tick-color-mode-light:rgb(150,150,150);
}

.grid-line-mode-light {
  border-color: var(--grid-line-mode-light);
}


.input-maincontainer-mode-light{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  border-radius: 5px;
  border: 1px solid rgb(235,235,235);
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
  align-items: center;
  font-size: 14px;
}

.input-input-mode-light{
  position: absolute;
  display: flex;
  color: rgb(0, 100, 255);
  background-color: rgba(255,255,255,0);
  padding: 5px;
  border-radius: 5px;
  width: 99%;
  outline: none;
  transition: all 500ms ease;
  height: 100%;
  font-size: 14px;
  margin-top: 5px;
}

.input-label-mode-light{
  position: absolute;
  display: flex;
  align-items: center;
  color: rgb(150, 150, 150);
  background-color: rgba(255,255,255,0);
  padding: 5px;
  border-radius: 5px;
  width: 99%;
  outline: none;
  transition: all 500ms ease;
  height: 100%;
}

.input-dropdown-mode-light{
  position: absolute;
  background: rgba(255,255,255,1);
  width: 99%;
  transition: all 500ms ease;
  overflow-y: auto;
  padding: 5px;
  z-index: 9999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 5px 5px 10px rgba(200,200,200,0.5);
  border: 3px solid rgb(235,235,235);
}

.input-option-mode-light{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(150,150,150)
}

.input-option-mode-light:hover{
  background-color: rgb(200,200,200);
  color: white;
}

.shadow-mode-light{
  box-shadow: 5px 5px 15px rgba(235,235,235,0.5)
}

.datepicker-mode-light{
  display: flex;
  background-color: rgba(255,255,255,0);
  color: rgb(0, 100, 255);
  border-radius: 5px;
  border: 1px solid rgb(235,235,235);
  width: 100%;
  font-size: 14px;
  margin-bottom: 5px;
  height: 50px;
  align-items: center;
  font-size: 14px;
  padding: 5px;
  outline: none;
  transition: all 500ms ease;
}



.navbar-item-mode-light {
  color: rgb(100,100,100);
  padding: 10px;
  margin: 2px;
}


.navbar-item-mode-light:hover {
  color: rgb(255,255,255);
  background-color: rgb(100,100,100);
  transition: all 500ms ease;
  border-radius: 5px;
}

.navbar-item-selected-mode-light {
  color: rgb(255,255,255);
  background-color: rgb(100,100,100);
  transition: all 500ms ease;
  border-radius: 5px;
  padding: 10px;
  margin: 2px;
}

.icon-color-mode-light{
  color: rgb(200,200,200);
}

.icon-color-mode-light:hover{
  color: rgb(100,100,100);
}

.icon-color-mode-light-clicked{
  color: rgb(0,100,225);
}


.floating-panel-mode-light {
  display: flex;
  direction: column;
  justify-content: center;
  background-color: rgb(235, 235, 235);
  transition: 0.5s ease;
  box-shadow: 5px 5px 15px rgb(235,235,235);
  border-radius: 5px;
  padding: 10px;
}

.floating-panel-header-mode-light {
  display: flex;
  direction: column;
  justify-content: center;
  background-color: rgb(200, 200, 200);
  padding: 10px;
  border-bottom: 1px solid lightgray;
}


.chat-topic-mode-light {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: All 300ms ease-in-out;
  cursor: pointer;
}

.chat-topic-mode-light:hover{
  transform: scale(1.05);
  background-color: rgb(225,225,225);
}

.chat-topic-clicked-mode-light {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: All 300ms ease-in-out;
  background-color: rgb(225,225,225);
}



.chat-prompt-mode-light {
  display: flex;
  width: 60%;
  align-self: start;
  height: fit-content;
  padding: 10px;
  background-color: rgba(50,150,255,1);
  color: white;
  box-shadow: 5px 5px 5px rgba(50,50,50,0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  line-height: 1.5;
}

.chat-response-mode-light{
  display: flex;
  flex-direction: column;
  align-self: end;
  width: 75%;
  height: fit-content;
  padding: 10px;
  background-color: rgb(245,245,245);
  color: black;
  box-shadow: 5px 5px 5px rgba(200,200,200,0.5);
  border-radius: 10px;
  margin-bottom: 50px;
  line-height: 1.5;
}